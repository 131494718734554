.wscn-http404-container[data-v-2c8099c2] {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 40%;
  left: 50%;
}
.wscn-http404[data-v-2c8099c2] {
  position: relative;
  width: 1100px;
  padding: 0 50px;
  overflow: hidden;
}
.wscn-http404 .pic-404[data-v-2c8099c2] {
  position: relative;
  float: left;
  width: 600px;
  overflow: hidden;
}
.wscn-http404 .pic-404__parent[data-v-2c8099c2] {
  width: 100%;
}
.wscn-http404 .pic-404__child[data-v-2c8099c2] {
  position: absolute;
}
.wscn-http404 .pic-404__child.left[data-v-2c8099c2] {
  width: 80px;
  top: 17px;
  left: 220px;
  opacity: 0;
  animation-name: cloudLeft-2c8099c2;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}
.wscn-http404 .pic-404__child.mid[data-v-2c8099c2] {
  width: 46px;
  top: 10px;
  left: 420px;
  opacity: 0;
  animation-name: cloudMid-2c8099c2;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-delay: 1.2s;
}
.wscn-http404 .pic-404__child.right[data-v-2c8099c2] {
  width: 62px;
  top: 100px;
  left: 500px;
  opacity: 0;
  animation-name: cloudRight-2c8099c2;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}
@keyframes cloudLeft-2c8099c2 {
0% {
    top: 17px;
    left: 220px;
    opacity: 0;
}
20% {
    top: 33px;
    left: 188px;
    opacity: 1;
}
80% {
    top: 81px;
    left: 92px;
    opacity: 1;
}
100% {
    top: 97px;
    left: 60px;
    opacity: 0;
}
}
@keyframes cloudMid-2c8099c2 {
0% {
    top: 10px;
    left: 420px;
    opacity: 0;
}
20% {
    top: 40px;
    left: 360px;
    opacity: 1;
}
70% {
    top: 130px;
    left: 180px;
    opacity: 1;
}
100% {
    top: 160px;
    left: 120px;
    opacity: 0;
}
}
@keyframes cloudRight-2c8099c2 {
0% {
    top: 100px;
    left: 500px;
    opacity: 0;
}
20% {
    top: 120px;
    left: 460px;
    opacity: 1;
}
80% {
    top: 180px;
    left: 340px;
    opacity: 1;
}
100% {
    top: 200px;
    left: 300px;
    opacity: 0;
}
}
.wscn-http404 .bullshit[data-v-2c8099c2] {
  position: relative;
  float: left;
  width: 300px;
  padding: 30px 0;
  overflow: hidden;
}
.wscn-http404 .bullshit__oops[data-v-2c8099c2] {
  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
  color: #1482f0;
  opacity: 0;
  margin-bottom: 20px;
  animation-name: slideUp-2c8099c2;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
.wscn-http404 .bullshit__headline[data-v-2c8099c2] {
  font-size: 20px;
  line-height: 24px;
  color: #222;
  font-weight: bold;
  opacity: 0;
  margin-bottom: 10px;
  animation-name: slideUp-2c8099c2;
  animation-duration: 0.5s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;
}
.wscn-http404 .bullshit__info[data-v-2c8099c2] {
  font-size: 14px;
  line-height: 21px;
  color: grey;
  opacity: 0;
  margin-bottom: 30px;
  animation-name: slideUp-2c8099c2;
  animation-duration: 0.5s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
}
.wscn-http404 .bullshit__return-home[data-v-2c8099c2] {
  display: block;
  float: left;
  width: 110px;
  height: 36px;
  background: #1482f0;
  border-radius: 100px;
  text-align: center;
  color: #ffffff;
  opacity: 0;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  animation-name: slideUp-2c8099c2;
  animation-duration: 0.5s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
}
@keyframes slideUp-2c8099c2 {
0% {
    transform: translateY(60px);
    opacity: 0;
}
100% {
    transform: translateY(0);
    opacity: 1;
}
}